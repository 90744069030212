<template lang="pug">
  form-modal(:name='name', :title='title', :state='state')
    form
      input-wrapper(:validator='$v.contact.name', attribute='company name')
        help-label(help-message='finance.contacts.form.name', slot='label') Company name

        input.ui.input(
          placeholder='What is this company called?',
          v-model='contact.name',
          @blur='$v.contact.name.$touch()')

      .two.fields
        .field
          label.optional First name
          input.ui.input(
            placeholder='Contact first name',
            v-model='contact.firstName'
          )

        .field
          label.optional Last name
          input.ui.input(
            placeholder='Contact last name',
            v-model='contact.lastName')

      .two.fields
        input-wrapper(:validator='$v.contact.emailAddress', attribute='email')
          label.optional(slot='label') Email address
          input.ui.input(
            placeholder='Email address',
            v-model='contact.emailAddress',
            type='email',
            @blur='$v.contact.emailAddress.$touch()')

      .actions
        mp-button(blue, @click.prevent='addContact') {{ buttonLabel }}
        mp-button(basic, @click.prevent='$modal.hide(name)') Cancel
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import FormModal from "@/components/modals/FormModal.vue";
import HelpLabel from "@/components/elements/HelpLabel.vue";
import InputWrapper from "@/components/inputs/wrappers/FormInputWrapper.vue";
import createContactMutation from "@/graphql/mutations/finance/CreateContact.gql";
import FormModalHelpers from "@/mixins/FormModalHelpers";
import MpButton from "@/components/elements/MpButton.vue";

export default {
  components: {
    FormModal,
    HelpLabel,
    InputWrapper,
    MpButton
  },
  mixins: [FormModalHelpers],
  validations: {
    contact: {
      name: {
        required
      },
      emailAddress: {
        email
      }
    }
  },
  data() {
    return {
      state: "none",
      name: "quick-create-contact",
      contact: {}
    };
  },
  computed: {
    buttonLabel() {
      return `Add ${this.opts.resourceType}`;
    },
    title() {
      return `Add a ${this.opts.resourceType}`;
    }
  },
  methods: {
    addContact() {
      this.$v.contact.$touch();
      if (!this.$v.contact.$invalid) {
        this.saveResource({
          mutation: createContactMutation,
          variables: {
            input: {
              ...this.contact
            }
          }
        }).then(data => {
          this.$emit("created", data.data.createContact.contact);
        });
      }
    }
  }
};
</script>

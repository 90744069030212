<template lang="pug">
  resource-dropdown(
    query='contacts',
    :queryFields='queryFields',
    dropdownLabel='Select a contact',
    modelName='contact',
    v-model='contact',
    v-on='$listeners',
    :quick-create-component='modalComponent',
    :quick-create-opts='quickCreateOpts'
    :search-fields="searchFields"
  )
</template>

<script>
import clone from "ramda/src/clone";
import CreateContactModal from "@/components/modals/CreateContactModal.vue";
import ResourceDropdown from "./ResourceDropdown.vue";

export default {
  components: {
    ResourceDropdown
  },
  props: ["value"],
  data() {
    return {
      contact: clone(this.value),
      queryFields: [
        "attentionTo",
        "shortAddress",
        "address",
        "name",
        "firstName",
        "lastName"
      ],
      modalComponent: CreateContactModal,
      quickCreateOpts: {
        modalName: "quick-create-contact",
        resourceType: "contact"
      },
      searchFields: ["label", "name", "firstName", "lastName"]
    };
  },
  watch: {
    value(newContact) {
      this.contact = newContact;
    }
  }
};
</script>

<template lang="pug">
  mp-form
    .stackable.ui.grid
      .seven.wide.column
        form-input-wrapper(
          v-for='field in structureKeyFields',
          :key='field.name',
          :validator='v[modelName][field.name]'
          :attribute='field.label.toLowerCase()')

          help-label.large(
            v-if='field.help',
            :help-message='field.help',
            slot='label'
            :class='{ "optional": !field.required }'
          ) {{ field.label }}

          label.large(
            v-else,
            slot='label',
            :class='{ "optional": !field.required }') {{ field.label }}


          input.ui.input(
            v-if='fieldIs(field, "input")',
            v-model='model[field.name]',
            :placeholder='fieldPlaceholder(field)',
            @blur='touchField(v[modelName][field.name])',
            @input='onInput'
          )

          project-dropdown(
            v-else-if='fieldIs(field, "project")',
            :name='field.name',
            v-model='model[field.name]',
            integration-type='accounting',
            :integration='model.integration',
            :mode='newRecord ? "normal" : "integration"',
            @close='touchField(v[modelName][field.name])',
            @input='onInput'
          )

          contact-dropdown(
            v-else-if='fieldIs(field, "contact")',
            :name='field.name',
            v-model='model[field.name]',
            @selected='recipientSelected',
            @close='touchField(v[modelName][field.name])',
            @input='onInput'
          )

          .ui.message(
            v-if='fieldIs(field, ["contact"]) && recipient && recipient.address',
          )
            strong {{ recipient.attentionTo }}
            address {{ recipient.address }}

      .seven.wide.right.floated.column
        .field
          label(class='large') {{ structure.resourceName }} Details

          .two.fields
            template(v-for='field in structure.detailFields')
              form-input-wrapper(
                v-if='shouldShowField(field)'
                :key='field.name',
                :validator='v[modelName][field.name]'
                :attribute='field.label.toLowerCase()')

                help-label.thin(
                  v-if='field.help && isLabelVisible(field)',
                  slot='label',
                  :help-message='field.help',
                  :class='{ "optional": !field.required }') {{ field.label }}

                label.thin(
                  v-else-if='isLabelVisible(field)',
                  slot='label',
                  :class='{ "optional": !field.required }') {{ field.label }}

                input.ui.input(
                  v-if='fieldIs(field, ["input", "sequence"])',
                  v-model='model[field.name]',
                  :placeholder='fieldPlaceholder(field)',
                  @blur='touchField(v[modelName][field.name])',
                  @input='onInput'
                )

                finance-status-dropdown(
                  v-else-if='fieldIs(field, "status")',
                  v-model='model[field.name]',
                  :variation='field.variation',
                  :value='model[field.name]',
                  @input='onInput'
                )

                date-picker(
                  v-else-if='fieldIs(field, "date")',
                  v-model='model[field.name]',
                  :placeholder='fieldPlaceholder(field)',
                  :min-date='model[field.min]'
                  @blur='touchField(v[modelName][field.name])',
                  @input='onInput'
                )

                currency-dropdown(
                  v-else-if='fieldIs(field, "currency")',
                  v-model='model[field.name]',
                  v-on:selected='currencySelected'
                  @close='touchField(v[modelName][field.name])',
                  @input='onInput'
                )

                exchange-rate-input(
                  v-else-if='fieldIs(field, "exchangeRate") && !isBaseCurrency()',
                  v-model='model[field.name]',
                  :value='model[field.name]',
                  :currency='currency',
                  @blur='touchField(v[modelName][field.name])',
                  @input='onInput'
                )

                number-input(
                  v-else-if='fieldIs(field, "money")',
                  v-model='model[field.name]',
                  type='currency',
                  :currency='currency',
                  @input='onInput'
                )

    .ui.divider

    .two.column.ui.grid
      .column
        label.large Items

      .right.aligned.column
        strong Amounts are tax {{ taxInclusiveText }}

    .hidden.ui.divider

    form-wrapper(:validator='v[modelName].lineItems')
      unique-form-errors

    line-item-table(
      v-model='model',
      :structure='structure.lineItems',
      :currency='model.currency',
      :project='model.project'
      :v='v[modelName].lineItems',
      v-on:delete-line-item='deleteLineItem',
      @input='onInput'
    )

    .hidden.ui.divider
    .one.column.grid
      .column
        mp-button(size='small', basic, @click.prevent='addLineItem()') Add line item
        mp-button(size='small', basic, @click.prevent='clearLineItems()') Clear line items

    .dashed.section.ui.divider

    .stackable.ui.grid
      .row
        .eight.wide.column
          .full.height.field(v-if='structure.descriptionField')
            textarea(
              :placeholder='structure.descriptionField.placeholder',
              v-model='model[structure.descriptionField.name]',
              @input='onInput'
            )

        .six.wide.right.floated.column
          .ui.message
            .totals.list
              div
                label Net:
                number-input(
                  :value='netTotal',
                  :read-only='true',
                  type='currency',
                  :currency='currency',
                )

              .breakdown(v-if='structure.lineItems.type === "markup"')
                label Markup:
                number-input(
                  :value='markupTotal',
                  :read-only='true',
                  type='currency',
                  :currency='currency',
                )

              template(v-if='structure.lineItems.type === "taxable"')
                .breakdown(v-for='taxBreakdown in taxes')
                  label Total tax at {{ taxBreakdown.taxRate.effectiveRate | decimalPlaces(2) }}%
                  number-input(
                    :value='taxBreakdown.taxAmount',
                    :read-only='true',
                    type='currency',
                    :currency='currency'
                  )


              .total
                label Total:
                number-input(
                  :value='totalAmount',
                  :read-only='true',
                  type='currency',
                  :currency='currency',
                )


              template(v-if='structure.calculateProfit')
                .breakdown
                  label Estimated GDV
                  number-input(
                    :value='model.gdv || 0',
                    :read-only='true',
                    type='currency',
                    :currency='currency'
                  )
                .breakdown
                  label Estimated profit
                  number-input(
                    :value='estimatedProfit',
                    :read-only='true',
                    type='currency',
                    :currency='currency'
                  )

    .ui.dashed.section.divider(v-if='structure.additionalFields')

    .stackable.ui.four.column.grid(v-if='structure.additionalFields')
      .column(v-for='(fields, index) in structure.additionalFields', :key='index')
        form-input-wrapper(
          v-for='field in fields',
          :key='field.name',
          :validator='v[modelName][field.name]'
          :attribute='field.label.toLowerCase()',
          :full-height='fieldIs(field, "text")')

          label.thin(
            slot='label',
            :class='{ "optional": !field.required }') {{ field.label }}

          input.ui.input(
            v-if='fieldIs(field, "input")',
            v-model='model[field.name]',
            :placeholder='fieldPlaceholder(field)',
            @input='onInput'
          )

          textarea(
            v-if='fieldIs(field, "text")'
            :placeholder='field.placeholder',
            v-model='model[field.name]'
          )

    .ui.section.divider

    .blue.ui.button(
      @click.prevent='$emit("save")',
      :class='{ disabled: saving }') {{ submitButtonLabel }}

    .basic.ui.button(
      @click.prevent='$emit("cancel")',
      :class='{ disabled: saving }') Cancel
</template>

<script>
/**
 * Please leave these ramda imports as individuals. Yes, importing in one go
 * is faster, but this stops tree-shaking working which we don't want to do.
 */
import clone from "ramda/src/clone";
import sum from "ramda/src/sum";
import map from "ramda/src/map";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import values from "ramda/src/values";
import reject from "ramda/src/reject";
import isNil from "ramda/src/isNil";
import reduce from "ramda/src/reduce";

import gql from "graphql-tag";
import NumberInput from "@/components/inputs/NumberInput.vue";
import FormInputWrapper from "@/components/inputs/wrappers/FormInputWrapper.vue";
import ExchangeRateInput from "@/components/inputs/ExchangeRateInput.vue";
import FinanceFormMixins from "@/mixins/FinanceForms";
import ContactDropdown from "@/components/inputs/dropdowns/ContactDropdown.vue";
import CurrencyDropdown from "@/components/inputs/dropdowns/CurrencyDropdown.vue";
import ProjectDropdown from "@/components/inputs/dropdowns/ProjectDropdown.vue";
import FinanceStatusDropdown from "@/components/inputs/dropdowns/FinanceStatusDropdown.vue";
import LineItemTable from "@/components/finance/LineItemTable.vue";
import DatePicker from "@/components/inputs/inputs/DatePicker.vue";
import MpForm from "@/components/containers/MpForm.vue";
import MpButton from "@/components/elements/MpButton.vue";
import HelpLabel from "@/components/elements/HelpLabel.vue";
import UniqueFormErrors from "@/components/collections/UniqueFormErrors.vue";

export default {
  components: {
    ContactDropdown,
    CurrencyDropdown,
    ProjectDropdown,
    FinanceStatusDropdown,
    LineItemTable,
    NumberInput,
    ExchangeRateInput,
    FormInputWrapper,
    DatePicker,
    MpForm,
    MpButton,
    HelpLabel,
    UniqueFormErrors
  },
  filters: {
    decimalPlaces(value, dp) {
      return value.toFixed(dp);
    }
  },
  mixins: [FinanceFormMixins],
  props: {
    structure: Object,
    value: Object,
    submitLabel: String,
    v: Object,
    modelName: String,
    loading: Boolean,
    saving: Boolean
  },
  data() {
    return {
      model: clone(this.value),
      currency: this.$store.state.defaultCurrency,
      lineItemKey: this.value.lineItems.length - 1,
      recipient: this.value.contact
    };
  },
  apollo: {
    sequence: {
      query() {
        return gql`{
          sequence(sequenceType: "${this.modelName}") {
            nextValue
          }
        }`;
      },
      update(data) {
        return data.sequence.nextValue;
      },
      skip() {
        return !this.hasSequenceInput;
      }
    }
  },
  computed: {
    newRecord() {
      return !!(this.model.id === undefined || this.model.id === null);
    },
    submitButtonLabel() {
      if (this.saving) {
        return "Please wait...";
      }

      return this.submitLabel;
    },
    detailFields() {
      return this.structure.detailFields.reduce(
        (result, value, index, array) => {
          if (index % 2 === 0) {
            result.push(array.slice(index, index + 2));
          }
          return result;
        },
        []
      );
    },
    markupTotal() {
      return sum(map(propOr(0, "markupTotal"), this.model.lineItems));
    },
    netTotal() {
      return sum(
        map(
          item => propOr(0, "quantity")(item) * propOr(0, "unitCost")(item),
          this.model.lineItems
        )
      );
    },
    taxInclusiveText() {
      return this.amountsTaxInclusive ? "inclusive" : "exclusive";
    },
    totalAmount() {
      if (this.structure.lineItems.type === "markup") {
        return this.netTotal + this.markupTotal;
      }

      return this.netTotal + this.taxTotal;
    },
    taxTotal() {
      return sum(map(prop("taxAmount"), values(this.taxes)));
    },
    taxes() {
      const extractTax = value => {
        const { taxRate } = value;
        if (!taxRate) {
          return null;
        }

        const taxableAmount = value.quantity * value.unitCost;
        const taxAmount = taxableAmount * (taxRate.effectiveRate / 100.0);

        return { taxRate, taxAmount };
      };

      const grouped = (acc, tax) => {
        if (!acc[tax.taxRate.id]) {
          acc[tax.taxRate.id] = tax;
        } else {
          acc[tax.taxRate.id].taxAmount += tax.taxAmount;
        }

        return acc;
      };

      return reduce(
        grouped,
        {},
        reject(isNil, map(extractTax, this.model.lineItems))
      );
    },
    hasSequenceInput() {
      return (
        map(prop("type"), this.structure.keyFields).indexOf("sequence") !==
          -1 ||
        map(prop("type"), this.structure.detailFields).indexOf("sequence") !==
          -1
      );
    },
    estimatedProfit() {
      return this.model.gdv - this.netTotal;
    },
    structureKeyFields() {
      if (this.$route.params.id && this.$route.path.includes("new")) {
        return this.structure.keyFields.filter(
          field => !this.fieldIs(field, "project")
        );
      } else {
        return this.structure.keyFields;
      }
    }
  },
  watch: {
    value: {
      handler(newModel) {
        this.model = clone(newModel);
      },
      deep: true
    },

    "model.currency": function currency() {
      if (this.isBaseCurrency) {
        this.model.exchangeRate = 1.0;
      }
    }
  },
  methods: {
    onInput() {
      this.$emit("input", this.model);
    },
    currencySelected(newCurrency) {
      this.currency = newCurrency;
    },
    recipientSelected(recipient) {
      this.recipient = recipient;
    },
    fieldIs(field, type) {
      return type.indexOf(field.type) !== -1;
    },
    shouldShowField(field) {
      if (field.type === "exchangeRate") {
        return !this.isBaseCurrency();
      }

      return true;
    },
    addLineItem() {
      this.lineItemKey += 1;
      this.model.lineItems.push({ key: this.lineItemKey });
      this.onInput();
    },
    clearLineItems() {
      this.lineItemKey += 1;
      this.model.lineItems = [{ key: this.lineItemKey }];
      this.onInput();
    },
    deleteLineItem(idx) {
      this.model.lineItems.splice(idx, 1);
      if (this.model.lineItems.length === 0) {
        this.addLineItem();
      } else {
        this.onInput();
      }
    },
    isBaseCurrency() {
      return this.model.currency.id === this.$store.state.defaultCurrency.id;
    },
    isLabelVisible(field) {
      if (this.fieldIs(field, "exchangeRate") && this.isBaseCurrency()) {
        return false;
      }

      return true;
    },
    getErrorMessage(fieldKey, validationKey) {
      const message = this.$vuelidateErrorExtractor.messages[validationKey];
      const regx = /{(.*?)}/g;

      return message.replace(regx, this.getLineItemFieldName(fieldKey));
    },
    getLineItemFieldName(key) {
      return key.replace(/([A-Z])/g, $1 => ` ${$1.toLowerCase()}`);
    },
    fieldPlaceholder(field) {
      if (field.type === "sequence") {
        return this.sequence;
      }
      return field.placeholder;
    },
    touchField(validator) {
      if (!validator) {
        return;
      }
      validator.$touch();
    }
  }
};
</script>

<style scoped>
/* .ui.form label.large {
    font-size: 1.3rem !important;
    margin-bottom: 1rem !important;
  }

  .ui.form label.thin {
    font-weight: 300 !important;
  }

  .ui.form .full.height {
    height: 100%;
  } */
</style>
